import { release } from "process";
import configs from "../utils/configs";
import { getCurrentHubId } from "../utils/hub-utils";
import { waitForDOMContentLoaded } from "./async-utils";

let avatarRig = null;
let holdPositionIntervalID;
const holdPositionIntervalDuration = 10000; //value in ms
let prevAvatarRigPosition = null;
let currentAvatarRigPosition = new THREE.Vector3();
let holdPositionEventSend = false;
import { getLocale } from "./i18n";

function removeTrailingSlash(str) {
  return str.replace(/\/+$/, "");
}

export const eventActionType = {
  openRoom: "open_room",
  enterRoom: "enter_room",
  usePortal: "use_portal",
  clickObject: "click_object",
  holdPosition: "hold_position",
  teleport: "teleport",
  leaveRoom: "leave_room"
};

const formdata_request = {
  method: "POST",
  headers: { Accept: "application/json", "X-Requested-With": "XMLHttpRequest", "Content-Type": "application/json" }
};

export async function uploadProjectAttachment(base64Image, project_id, accessToken) {
  try {
    const data = { ...formdata_request };
    // const formdata = new FormData();
    // formdata.append("name", "attachment");
    // formdata.append("attachment", attachment_file);
    // console.log("attachment_file.name :>> ", attachment_file?.name);
    // formdata.append("file_name", attachment_file.name);
    if (!project_id) {
      throw new Error("Project Id not present");
    }
    //for authentication pass the imedu-token and the hubs token
    data.headers["imedu-token"] = configs?.IMEDU_TOKEN || "";
    data.headers["Authorization"] = `Bearer ${accessToken}`;
    // data.body = formdata;
    data.body = JSON.stringify({
      base64_image: base64Image,
      file_name: "photo_" + Date.now() + ".png"
    });
    let api;
    if (configs?.CLASS_MANAGER) {
      api = removeTrailingSlash(configs.CLASS_MANAGER);
    } else {
      throw new Error("Api path not present");
    }
    const response = await fetch(`https://${api}/api/v1/project_attachments/${project_id}/`, data);

    if (!response.ok) {
      const errorMessage = await response.text();
      if (typeof errorMessage === "string" && errorMessage.length > 1) {
        throw new Error(errorMessage);
      }
      throw new Error(`Server responded with ${response.status}`);
    }

    const result = await response.json();
    // console.log("result :>> ", result);
    // need to update;
    return { result };
  } catch (error) {
    console.error("Error uploading image:", error);
    return { error };
  }
}
/**
 * ret active avatar word position
 * @returns object ex: {x, y, z}
 */
export function getUserCoordinate() {
  const avatarRig3D = document.getElementById("avatar-rig")?.object3D;
  return {
    x: avatarRig3D?.position?.x,
    y: avatarRig3D?.position?.y,
    z: avatarRig3D?.position?.z
  };
}

/**
 * Monitor user activity within Hubs rooms And pass it to backend
 * @param {string} action select from eventActionType
 * @param {object} data
 * @returns
 */
export async function passMonitoringEvent(action, data = {}, useBeacon = false) {
  try {
    // console.log("action, data :>> ", { action, data });
    // console.log(" data :>> ", data);

    const myHeaders = new Headers();
    myHeaders.append("imedu-token", configs.IMEDU_TOKEN);
    myHeaders.append("Content-Type", "application/json");
    const body = {
      action,
      datetime: new Date().toISOString(), //string in the ISO 8601 format EX: '2025-01-30T23:16:16.512Z'
      user_id: APP?.store?.state?.credentials?.token || "",
      client_id: configs?.CLIENT_ID || "",
      hubs_id: getCurrentHubId(),
      platform: {
        userAgent: navigator.userAgent, // Full User Agent String
        platform: navigator?.userAgentData?.platform || navigator?.platform, // OS Platform (e.g., "Win32", "Linux x86_64")
        language: navigator.language, // Preferred Language (e.g., "en-US")
        languages: navigator.languages, // All Preferred Languages
        online: navigator.onLine, // Is the browser online?
        cookiesEnabled: navigator.cookieEnabled, // Are cookies enabled?
        vendor: navigator.vendor, // Browser Vendor (e.g., "Google Inc.")
        product: navigator.product // Browser Engine (e.g., "Gecko")
      },
      device_type: APP?.scene?.is("vr-mode") ? "VR" : "2D"
    };

    switch (action) {
      case eventActionType.openRoom:
        body.date = {};
        break;
      case eventActionType.enterRoom:
        // adding 1s delay as it may take some frame delay befor user position is updated
        await new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, 1000);
        });
        body.date = {
          coordinates: getUserCoordinate()
        };
        break;
      case eventActionType.usePortal:
        body.date = data;
        break;
      case eventActionType.clickObject:
        body.date = data;
        break;
      case eventActionType.holdPosition:
        body.date = data;
        break;
      case eventActionType.teleport:
        body.date = data;
        break;
      case eventActionType.leaveRoom:
        body.date = data;
        break;

      default:
        console.error("invalid action -> ", action);
        return;
    }
    const eventUrl = `https://${configs.CLASS_MANAGER}/api/v1/app_event/`;
    // const eventUrl = `http://localhost:7020/api/beacon/`;
    if (useBeacon === true) {
      body["imedu-token"] = configs.IMEDU_TOKEN;
      navigator.sendBeacon(eventUrl, JSON.stringify(body));
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body)
    };
    // return;
    await fetch(eventUrl, requestOptions);
  } catch (error) {
    console.error(error);
  }
}

/**
 * this func runs on set interval, each time it check if user position has changed.
 * on position change call passMonitoringEvent
 * @returns undefined
 */
function monitorAvatarPositionChange() {
  // console.log("monitorAvatarPositionChange call");
  if (!APP.scene.is("entered")) return;
  // console.log("monitorAvatarPositionChange call", APP.scene.is("entered"));
  if (prevAvatarRigPosition === null) {
    prevAvatarRigPosition = new THREE.Vector3();
    prevAvatarRigPosition.copy(avatarRig.position);
    return;
  }
  currentAvatarRigPosition.copy(avatarRig.position);
  if (NAF.utils.almostEqualVec3(prevAvatarRigPosition, currentAvatarRigPosition, 0.1)) {
    if (!holdPositionEventSend) {
      passMonitoringEvent(eventActionType.holdPosition, {
        coordinates: { x: currentAvatarRigPosition.x, y: currentAvatarRigPosition.y, z: currentAvatarRigPosition.z }
      });
      holdPositionEventSend = true;
    }
  } else {
    if (holdPositionEventSend) {
      holdPositionEventSend = false;
    }
  }
  // console.log("object :>> ", NAF.utils.almostEqualVec3(prevAvatarRigPosition, currentAvatarRigPosition, 0.01));
  // console.log(
  //   "currentAvatarRigPosition :>> ",
  //   currentAvatarRigPosition.x,
  //   currentAvatarRigPosition.y,
  //   currentAvatarRigPosition.z
  // );
  // console.log("prevAvatarRigPosition :>> ", prevAvatarRigPosition.x, prevAvatarRigPosition.y, prevAvatarRigPosition.z);
  prevAvatarRigPosition.copy(avatarRig.position);
}

(() => {
  // console.log("() => {------------- call");
  waitForDOMContentLoaded().then(() => {
    avatarRig = document.getElementById("avatar-rig").object3D;
    // check for movement interval
    monitorAvatarPositionChange();
    holdPositionIntervalID = window.setInterval(() => {
      monitorAvatarPositionChange();
    }, holdPositionIntervalDuration);
  });
})();

/**
 * Custom Wrapper Populate URL Template value and to add language Locale as search param.
 * @param {string} url A string representing the URL of the resource
 * @returns string
 */
export function injectAndPopulatePathAndParams(url) {
  try {
    url = url.replace(/\{room_id\}/g, window?.APP?.hub?.hub_id);
    url = url.replace(/---room_id---/g, window?.APP?.hub?.hub_id);
    url = url.replace(/---space_id---/g, window?.APP?.hub?.user_data?.space_id);
    const tURL = new URL(url);
    if (tURL.host === configs.CLASS_MANAGER) {
      // add langues locale to search param
      tURL.searchParams.set("locale", getLocale());
    }
    // console.log("tURL.href---- :>> ", tURL.href);
    return tURL.href;
  } catch (error) {
    // If a user provides an invalid URL, let's just return it and pretend we didn't see anything.
    console.error(error);
    return url;
  }
}

/**
 * Custom Fetch Wrapper, use to add default options property
 * @param {string} url A string representing the URL of the resource
 * @param {object} Optional An object containing options that define the request's specifics
 * @returns Promise
 */
export function classManagerCustomFetchWrapper(url, Optional) {
  const tURL = new URL(url);
  if (tURL.host === configs.CLASS_MANAGER) {
    // add langues locale to search param
    url = injectAndPopulatePathAndParams(url);
    // console.log("url---- :>> ", url);
  }

  return fetch(url, Optional);
}
